.header-main {
  top: -100px;
  transition: top 0.5s ease-in-out;
}

.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
  background-color: #ffffff;
  padding-top: 0px;
  padding-bottom: 0px;
}

.toggle-menu-button {
  color: rgba(102, 102, 102, 0.85);
}

.toggle-menu-button:hover {
  color: rgba(17, 17, 17, 0.85);
}

.search-button {
  min-width: 2.5em;
  padding-left: 0.6em;
  padding-right: 0.6em;
  background: #007cba;
  width: 34px;
}

.search-button:hover {
  box-shadow: inset 0 0 0 100px rgba(0, 0, 0, 0.2);
}

.search-input {
  box-sizing: border-box;
  border: 1px solid #ddd;
  padding: 0 0.75em;
  height: 2.507em;
  font-size: 0.97em;
  border-radius: 0;
  max-width: 100%;
  width: 100%;
  vertical-align: middle;
  background-color: #fff;
  color: #333;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.search-input:focus-visible {
  outline: none;
}

.search-input:focus {
  box-shadow: 0 0 5px #ccc;
}

.cart-button {
  font-size: 0.8em;
}

.cart-button:hover {
  box-shadow: inset 0 0 0 100px rgba(0, 0, 0, 0.2);
}

.dropdown {
  position: relative;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown .dropdown-menu {
  position: absolute;
}