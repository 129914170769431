@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: sans-serif;
  }
}

.btn:hover {
  box-shadow: inset 0 0 0 100px rgba(0, 0, 0, 0.2);
}

.main-content {
  min-height: calc(100vh - 470px);
}

i[class^="icon-"],
i[class*=" icon-"] {
  font-family: "fl-icons" !important;
  font-display: block;
  speak: none !important;
  margin: 0;
  padding: 0;
  display: inline-block;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  position: relative;
  line-height: 1.2;
}

.icon-search:before {
  content: "";
}

.icon-shopping-basket:before {
  content: "";
}

.icon-shopping-bag:before {
  content: "";
}

.icon-expand:before {
  content: "";
}

.icon-angle-left:before {
  content: "";
}

.icon-angle-right:before {
  content: "";
}

.stars .star {
  font-size: 16px;
  margin-left: -10px;
  display: inline-block;
  color: transparent !important;
  overflow: hidden;
  font-family: "fl-icons";
  cursor: pointer;
}

.stars .star + .star {
  margin-left: 0.3em;
  border-left: 1px solid #ececec;
}

.stars .star:hover:after,
.stars .star.active:after {
  color: #d26e4b;
}

.stars .star:after {
  color: #ddd;
  content: "";
}

.stars .star.star-2:after {
  content: "";
}

.stars .star.star-3:after {
  content: "";
}

.stars .star.star-4:after {
  content: "";
}

.stars .star.star-5:after {
  content: "";
}
